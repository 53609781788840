import { fDate } from '@/actions/Helper';
import React from 'react';
import { useDrag } from 'react-dnd';
import styles1 from '../../screens/StreetTurn/styles';

export default ({ DATA = [], idx = {}}) => {
  const cls = styles1();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'BOX',
    item: { id: DATA.id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  return (
    // <img
    //   ref={drag}
    //   src={url}
    //   width="150px"
    //   style={{ border: isDragging ? '5px solid pink' : '0px' }}
    // />
      <tr
        ref={drag}
        style={{ border: isDragging ? '1px solid #000' : '0px' }}
        className={cls.tableRowDragDrip}
        key={idx}>
        <td style={{ flex: 1 }}>#5reg61ge</td>
        <td style={{ flex: 1 }}>#5fvrer45</td>
        <td style={{ flex: 1 }}>{fDate(DATA.date)}</td>
        <td style={{ flex: 1.2 }}>Pacific Container</td>
        <td style={{ flex: 1 }}>{DATA.loc}</td>
      </tr>

  );
};
