import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import styles from './style';
import Picture from './Picture';
import styles1 from '../../screens/StreetTurn/styles';
import { fDate } from '@/actions/Helper';

export default ({ DATA = [] }) => {
  const cls = styles1();

  const PictureList = [
    {
      id: 1,
      url: 'https://icons.veryicon.com/png/o/miscellaneous/cockpit/drag-4.png',
    },
    {
      id: 2,
      url: 'https://icons.veryicon.com/png/o/miscellaneous/cockpit/drag-4.png',
    },
    {
      id: 3,
      url: 'https://icons.veryicon.com/png/o/miscellaneous/cockpit/drag-4.png',
    },
  ];

  const [board, setBoard] = useState([]);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'image',
    drop: (item) => addImageToBoard(item.id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const addImageToBoard = (id) => {
    console.log(id);

    const pictureList = PictureList.filter((picture) => id === picture.id);
    setBoard((board) => [...board, pictureList[0]]);
  };
  return (
    <>
      <div className={styles.picture}>
        <h2 className={cls.title}>Your Empties</h2>
        <table id="listTableCenter" className={cls.listTableCenter}>
          <thead className={cls.thead}>
            <th style={{ flex: 1 }}>Container #</th>
            <th style={{ flex: 1 }}>Chassis #</th>
            <th style={{ flex: 1 }}>LFD</th>
            <th style={{ flex: 1.2 }}>Return Terminal</th>
            <th style={{ flex: 1 }}>Location</th>
          </thead>
          <tbody className={cls.tbody}>
            {DATA.map((i, idx) => (
              <Picture DATA={i} idx={idx} />
            ))}
          </tbody>
        </table>
      </div>

      <table className={cls.listTableMatch}>
        <thead className={cls.thead}>
          <th style={{ flex: 1 }}>...</th>
        </thead>
        <tbody className={cls.tbody}>
          {DATA.map((i, idx) => (
            <tr className={cls.tableRow} key={idx}>
              <td className={cls.matched} style={{ flex: 1 }}></td>
            </tr>
          ))}
        </tbody>
      </table>

      <div
        // style={{ background: 'red', width: '300px', height: '300px' }}
        className={styles.Board}
        ref={drop}>
        <h2 className={cls.title}>Your Exports</h2>
        <table className={cls.listTableRight}>
          <thead className={cls.thead}>
            <th style={{ flex: 1.5 }}>Booking #</th>
            <th style={{ flex: 1.5 }}>ERD</th>
            <th style={{ flex: 1.5 }}>Cutoff</th>
            <th style={{ flex: 1.8 }}>Terminal</th>
            <th style={{ flex: 1.5 }}>Location</th>
            <th style={{ flex: 1.1 }}>Saving</th>
          </thead>
          <tbody className={cls.tbody}>
            {DATA.map((i, idx) => (
              <tr className={cls.tableRow} key={idx}>
                <td style={{ flex: 1.5 }}>#151653</td>
                <td style={{ flex: 1.5 }}>{fDate(i.date)}</td>
                <td style={{ flex: 1.5 }}>{fDate(i.date)}</td>
                <td style={{ flex: 1.8 }}>LAP</td>
                <td style={{ flex: 1.5 }}>{i.loc}</td>
                <td style={{ flex: 1.1 }}>300$</td>
              </tr>
              // <Picture url={idx} id={i.id} />
            ))}
          </tbody>
        </table>
        {/* {DATA.map((i, idx) => {

        // {board.map((picture) => {
          return <Picture url={i.idx} id={i.id} />;
        })} */}
      </div>
    </>
  );
};
