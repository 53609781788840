import React, { useEffect, useState } from 'react';
import { fDate } from '@/actions/Helper';
import styles from './styles';
import Containers from './Containers';
import Share from './Share/index';
import { getInventoryShareLink } from '@/actions';
import { useNotify } from 'react-admin';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag } from 'react-dnd';
import DragDrop from '../../components/DragDrop/index';

export default ({ DATA = [], Del, Edit, getInventoryList, currentPage }) => {
  const cls = styles();
  const [showContainersModal, setShowContainersModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [shareLink, setShareLink] = useState({
    loading: null,
    DATA: null,
  });
  // console.log('ddata', DATA);

  const notify = useNotify();

  const getActivContainers = (inventory, target) => {
    if (inventory) {
      let activeContainers = [];
      let deactiveContainers = [];
      inventory.containers.forEach((container) => {
        if (container.status === 1) {
          activeContainers.push(container);
        } else if (container.status === 0) {
          deactiveContainers.push(container);
        }
      });

      if (deactiveContainers.length === 0) {
        return (
          <input
            type="checkbox"
            className={`all ${cls.containerChecBox}`}
            checked={true}
          />
        );
      } else if (activeContainers.length === 0) {
        return (
          <input
            type="checkbox"
            className={`any ${cls.containerChecBox}`}
            checked={false}
          />
        );
      } else {
        return (
          <input
            type="checkbox"
            checked={true}
            className={`some ${cls.containerChecBox}`}
          />
        );
      }
    }
  };

  const handleGetShareLink = (i) => {
    setShareLink({ loading: i.id });
    getInventoryShareLink(i.id)
      .then((res) => {
        setShareModal(i);
        setShareLink({
          loading: null,
          DATA: res,
        });
      })
      .catch((err) => {
        setShareLink({ loading: null });
        notify(err, 'warning');
      });
  };

  useEffect(() => {
    let someCompletedCheckboxs = document.getElementsByClassName('some');
    let allCompletedCheckboxs = document.getElementsByClassName('all');
    let notCompletedCheckboxs = document.getElementsByClassName('any');
    for (let i = 0; i < someCompletedCheckboxs.length; i++) {
      someCompletedCheckboxs[i].indeterminate = true;
    }
    for (let i = 0; i < allCompletedCheckboxs.length; i++) {
      allCompletedCheckboxs[i].indeterminate = false;
    }
    for (let i = 0; i < notCompletedCheckboxs.length; i++) {
      notCompletedCheckboxs[i].indeterminate = false;
      notCompletedCheckboxs[i].checked = false;
    }
  }, [getActivContainers, getInventoryList]);

  return (
    <div id="amirbios" className={cls.listContainer}>
      {showContainersModal && selectedRow && (
        <Containers
          open={showContainersModal}
          DATA={DATA}
          selectedRow={selectedRow}
          setShowContainersModal={setShowContainersModal}
          getInventoryList={getInventoryList}
        />
      )}
      {shareModal && !shareLink.loading && (
        <Share
          shareLink={shareLink.DATA}
          shareModal={shareModal}
          setShareModal={setShareModal}
        />
      )}
      <div>
        <span className={cls.emptySpan}></span>
        <table className={cls.listTableLeft}>
          <thead className={cls.thead}>
            <th style={{ flex: 0.5 }}>#</th>
            <th style={{ flex: 1.5 }}>Size</th>
            <th style={{ flex: 1.5 }}>SSL</th>
          </thead>
          <tbody className={cls.tbody}>
            {DATA.map((i, idx) => (
              <tr className={cls.tableRow} key={idx}>
                <td style={{ flex: 0.5 }}>
                  {idx + 1 + (currentPage - 1) * 10}
                </td>
                <td style={{ flex: 1.5 }}>{i.size}</td>
                <td style={{ flex: 1.5 }}>{i.ssl}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <DndProvider backend={HTML5Backend}>
        <DragDrop DATA={DATA} />
      </DndProvider>
    </div>
  );
};
