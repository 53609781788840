import React, { useEffect, useState } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import {
  DeleteRounded,
  CreateRounded,
  Share as ShareIcon,
  Done,
  Close,
} from '@material-ui/icons';
import { fDate, fTimeDate } from '@/actions/Helper';
import styles from './styles';
import bookings from './Bookings';
// import Share from './Share/index';
import { getInventoryShareLink } from '@/actions';
import { useNotify } from 'react-admin';
import Bookings from './Bookings';

export default ({
  DATA = [],
  Del,
  Edit,
  getExportInventoryList,
  currentPage,
}) => {
  const cls = styles();
  const [showBookingsModal, setShowBookingsModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [shareLink, setShareLink] = useState({
    loading: null,
    DATA: null,
  });

  const notify = useNotify();

  const getBookingNumbers = (exportList, target) => {
    if (exportList) {
      let activeBookings = [];
      let deactiveBookings = [];
      exportList.bookings.forEach((booking) => {
        if (booking.status === 1) {
          activeBookings.push(booking);
        } else if (booking.status === 0) {
          deactiveBookings.push(booking);
        }
      });

      if (deactiveBookings.length === 0) {
        return (
          <input
            type="checkbox"
            className={`all ${cls.containerChecBox}`}
            checked={true}
          />
        );
      } else if (activeBookings.length === 0) {
        return (
          <input
            type="checkbox"
            className={`any ${cls.containerChecBox}`}
            checked={false}
          />
        );
      } else {
        return (
          <input
            type="checkbox"
            checked={true}
            className={`some ${cls.containerChecBox}`}
          />
        );
      }
    }
  };

  const handleGetShareLink = (i) => {
    setShareLink({ loading: i.id });
    getInventoryShareLink(i.id)
      .then((res) => {
        setShareModal(i);
        setShareLink({
          loading: null,
          DATA: res,
        });
      })
      .catch((err) => {
        setShareLink({ loading: null });
        notify(err, 'warning');
      });
  };

  useEffect(() => {
    let someCompletedCheckboxs = document.getElementsByClassName('some');
    let allCompletedCheckboxs = document.getElementsByClassName('all');
    let notCompletedCheckboxs = document.getElementsByClassName('any');
    for (let i = 0; i < someCompletedCheckboxs.length; i++) {
      someCompletedCheckboxs[i].indeterminate = true;
    }
    for (let i = 0; i < allCompletedCheckboxs.length; i++) {
      allCompletedCheckboxs[i].indeterminate = false;
    }
    for (let i = 0; i < notCompletedCheckboxs.length; i++) {
      notCompletedCheckboxs[i].indeterminate = false;
      notCompletedCheckboxs[i].checked = false;
    }
  }, [getBookingNumbers, getExportInventoryList]);

  return (
    <div className={cls.listContainer}>
      {showBookingsModal && selectedRow && (
        <Bookings
          open={showBookingsModal}
          DATA={DATA}
          selectedRow={selectedRow}
          setShowBookingsModal={setShowBookingsModal}
          getExportInventoryList={getExportInventoryList}
        />
      )}

      <table className={cls.list}>
        <thead className={cls.thead}>
          <th style={{ flex: 0.35 }}>#</th>
          <th style={{ flex: 1 }}>Size</th>
          <th style={{ flex: 0.7 }}>SSL</th>
          <th style={{ flex: 0.7 }}>Active</th>
          <th style={{ flex: 1.2 }}>Location</th>
          <th style={{ flex: 1 }}>Available Date</th>
          <th style={{ flex: 0.7 }}>Own Chassis</th>
          <th style={{ flex: 0.7 }}>Pool Chassis</th>
          <th style={{ flex: 1 }}>Last Update</th>
          <th style={{ flex: 1 }}>Action</th>
        </thead>
        <tbody className={cls.tbody}>
          {DATA.map((i, idx) => (
            <tr className={cls.tableRow} key={idx}>
              <td style={{ flex: 0.35 }}>{idx + 1 + (currentPage - 1) * 10}</td>
              <td style={{ flex: 1 }}>{i.size}</td>
              <td style={{ flex: 0.7 }}>{i.ssl}</td>
              <td style={{ flex: 0.7 }}>{i.active}</td>
              <td style={{ flex: 1.2 }}>{i.loc}</td>
              <td style={{ flex: 1 }}>{fDate(i.date)}</td>
              <td style={{ flex: 0.7 }}>
                {i.own_chases_count ? <Done /> : <Close />}
              </td>
              <td style={{ flex: 0.7 }}>
                {i.pool_chases_count ? <Done /> : <Close />}
              </td>
              <td style={{ flex: 1 }}>{fTimeDate(i.up)}</td>
              <td className={cls.tabActions} style={{ flex: 1 }}>
                <Tooltip arrow title="Edit" placement="top">
                  <IconButton aria-label="edit" onClick={() => Edit(i)}>
                    <CreateRounded className={cls.editIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Bookings" placement="top">
                  <IconButton
                    onClick={() => {
                      setShowBookingsModal(!showBookingsModal);
                      setSelectedRow(i);
                    }}>
                    {/* <img src={getActivBookings(i)} /> */}
                    {getBookingNumbers(i)}
                  </IconButton>
                </Tooltip>

                <Tooltip arrow title="Delete" placement="top">
                  <IconButton aria-label="delete" onClick={() => Del(i.id)}>
                    <DeleteRounded className={cls.delIcon} />
                  </IconButton>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
