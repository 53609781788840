import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({

  App: {
    textAlign: "center",
    width: "100vw",
    height: "100vh",
  },
  
  Pictures: {
    margin: "10px",
  },
  img: {
    margin: "5px",
  },
  
  Board: {
    width: "300px",
    height: "500px",
    border: "2px solid black",
    background: "red",
  },
}))