import React, { useEffect, useState } from 'react';
import { Loading } from 'react-admin';
import {
  Button,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import DateInput from '@/components/DateInput';
import { locationOpt } from '@/actions/Helper';
import styles from './styles';
import { addExportInventory, getTerminalsList } from '@/actions';
import { format } from 'date-fns';

export default ({ notify, setData, getExportInventoryList }) => {
  const cls = styles();
  const [loading, setLoading] = React.useState(false);
  const selectOp = useSelector((state) => state.reducer);
  const [terminalsList, setTerminalsList] = useState([]);
  const [showBookingNames, setShowBookingNames] = useState(true);
  const [showbookings, setShowBookings] = React.useState(false);
  const [terminalsSelectOptions, setTerminalsSelectOptions] = useState([]);
  const [selectedTerminal, setSelectedTerminal] = useState('All');

  const [form, setFormData] = React.useState({
    size: null,
    ssl: null,
    date: new Date(),
    count: '',
    loc: null,
    bookingNumber: null,
    erd: null,
    cutoff: null,
    bol: null,
    mto: null,
    appointment_date: null,
    chassis: {
      own: true,
      pool: true,
    },
  });
  const getTerminalsListFanc = () => {
    setLoading(true);
    getTerminalsList()
      .then((res) => {
        const terminalsRes = Object.values(res.data.terminals);
        if (terminalsRes) {
          setTerminalsList(terminalsRes); // Set the state directly
        }
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };
  useEffect(() => {
    getTerminalsListFanc();
  }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    if (terminalsList.length > 0) {
      const options = terminalsList.map((terminal, index) => ({
        label: terminal.name,
        slug: terminal.slug,
        value: index + 1,
      }));

      // Set portSelectOptions state
      setTerminalsSelectOptions(options);
    }
  }, [terminalsList]); // Dependencies array with portsList

  // useEffect(() => {
  //   let isShowBookings = localStorage.getItem('smart_user_show_bookings');
  //   let isShowBookingNames = localStorage.getItem(
  //     'smart_user_show_booking_names'
  //   );
  //   if (isShowBookings === 'true') {
  //     setShowBookings(true);
  //   }
  //   if (isShowBookingNames === 'false') {
  //     setShowBookingNames(false);
  //   }
  // }, []);

  const setForm = (it, val) => setFormData({ ...form, [it]: val });

  const Add = () => {
    setLoading(true);
    // Reset();
    addExportInventory({
      ...form,
    })
      .then((res) => {
        notify('Added Successfully.');
        getExportInventoryList();
        Reset();
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setData([]);
        setLoading(false);
      });
  };

  const Reset = () => {
    setFormData({
      size: null,
      ssl: null,
      date: new Date(),
      count: '',
      loc: null,
      bookingNumber: '',
      erd: null,
      cutoff: null,
      mto: null,
      appointment_date: null,

      chassis: {
        own: true,
        pool: true,
      },
    });
    setShowBookings(false);
  };
  return (
    <div className={cls.card}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} lg={2}>
          <div className={`${cls.input} ${cls.containerInput}`}>
            <span className={cls.containerBadge}>{`Size `}</span>
            <Select
              placeholder="Size"
              className={cls.select}
              value={form.size}
              onChange={(item) => setForm('size', item)}
              options={selectOp.size}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={4} lg={2}>
          <div className={`${cls.input} ${cls.containerInput}`}>
            <span className={cls.containerBadge}>{`SSL`}</span>
            <Select
              placeholder="SSL"
              className={clsx(cls.select)}
              value={form.ssl}
              onChange={(item) => setForm('ssl', item)}
              options={selectOp.ssl}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={4} lg={2}>
          <div className={`${cls.input} ${cls.containerInput}`}>
            <span className={cls.containerBadge}>{`Number `}</span>
            <TextField
              type="number"
              placeholder=""
              className={clsx(cls.input, cls.numberInput)}
              value={form.count}
              onChange={(e) => setForm('count', e.target.value)}
              InputProps={{ disableUnderline: true }}
              inputProps={{ min: 0 }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={4} lg={3}>
          <div className={`${cls.input} ${cls.containerInput}`}>
            <span className={cls.containerBadge}>{`Location `}</span>
            <AsyncSelect
              placeholder=""
              className={cls.loc}
              value={form.loc}
              loadOptions={locationOpt}
              onChange={(item) => setForm('loc', item)}
              cacheOptions
              defaultOptions
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <div className={`${cls.input} ${cls.containerInput}`}>
            <span className={cls.containerBadge}>{`Booking# `}</span>
            <TextField
              type="text"
              value={form.bookingNumber}
              placeholder={``}
              className={cls.textInput}
              InputProps={{ disableUnderline: true }}
              inputProps={{ min: 1 }}
              onChange={(item) => setForm('bookingNumber', item.target.value)}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={4} lg={2}>
          <div className={`${cls.input} ${cls.containerInput}`}>
            <span className={cls.containerBadge}>{`Date `}</span>
            <DateInput
              placeholder="Available date"
              className={cls.date}
              format="MM/dd/yyyy"
              value={form.date}
              onChange={(item) => setForm('date', item)}
            />
          </div>
        </Grid>

        {showBookingNames && (
          <Grid item xs={12}>
            <Grid xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={cls.checkbox}
                    checked={showbookings}
                    onChange={(e) => {
                      setShowBookings(e.target.checked);
                      localStorage.setItem(
                        'smart_user_show_bookings',
                        e.target.checked
                      );
                    }}
                  />
                }
                label="Show Additional Inputs"
              />
            </Grid>
            {showbookings && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} lg={2}>
                  <div className={`${cls.input} ${cls.containerInput}`}>
                    <span className={cls.containerBadge}>{`ERD `}</span>
                    <DateInput
                      placeholder="ERD"
                      className={cls.date}
                      format="MM/dd/yyyy"
                      value={form.erd}
                      onChange={(item) => setForm('erd', item)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} lg={2}>
                  <div className={`${cls.input} ${cls.containerInput}`}>
                    <span className={cls.containerBadge}>{`CutOff `}</span>
                    <DateInput
                      placeholder="CutOff"
                      className={cls.date}
                      format="MM/dd/yyyy"
                      value={form.cutoff}
                      onChange={(item) => setForm('cutoff', item)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} lg={2}>
                  <div className={`${cls.input} ${cls.containerInput}`}>
                    <span className={cls.containerBadge}>{`MTO `}</span>
                    <Select
                      placeholder="MTO"
                      className={clsx(cls.select)}
                      value={form.mto}
                      onChange={(item) => {
                        setForm('mto', item);
                        setSelectedTerminal(item);
                      }}
                      options={terminalsSelectOptions}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <div className={`${cls.input} ${cls.containerInput}`}>
                    <span className={cls.containerBadge}>{`Term Apt `}</span>
                    <DateInput
                      placeholder="Term Apt"
                      className={cls.date}
                      format="MM/dd/yyyy"
                      value={form.appointment_date}
                      onChange={(item) => setForm('appointment_date', item)}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={3}>
          <div className={cls.containerActions}>
            <div className={cls.containerRowRound}>
              <span
                onClick={() =>
                  setForm('chassis', {
                    own: !form.chassis.own,
                    pool:
                      !form.chassis.own === false ? true : form.chassis.pool,
                  })
                }
                className={clsx(
                  cls.chassisText,
                  form.chassis.own && cls.activeChassis
                )}>
                Own Chassis
              </span>
              <span
                onClick={() =>
                  setForm('chassis', {
                    pool: !form.chassis.pool,
                    own: !form.chassis.pool === false ? true : form.chassis.own,
                  })
                }
                className={clsx(
                  cls.chassisText,
                  form.chassis.pool && cls.activeChassis
                )}>
                Pool Chassis
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={cls.actions}>
        <span className={cls.text}>
          Please fill the form above and click on add button. You can edit or
          delete an request later through the below list
        </span>
        <div className={cls.buttons}>
          <Button className={clsx(cls.but, cls.red)} onClick={Reset}>
            Reset
          </Button>
          <Button className={clsx(cls.but, cls.blue)} onClick={Add}>
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};
