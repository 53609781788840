import React from 'react';
import { Route } from 'react-router-dom';
import SC from '@/screens';

export default [
  <Route exact path="/profile" component={SC.Profile} />,
  <Route exact path="/search" component={SC.SearchEmpty} />,
  <Route exact path="/search/empty" component={SC.SearchEmpty} />,
  <Route exact path="/search/export" component={SC.SearchExport} />,
  <Route exact path="/content/inventory" component={SC.AdminInventory} />,
  <Route exact path="/content/completed" component={SC.AdminCompleted} />,
  <Route exact path="/export" component={SC.AdminExportList} />,
  <Route exact path="/export/list" component={SC.AdminExportList} />,
  <Route exact path="/export/completed" component={SC.AdminExportCompleted} />,
  <Route exact path="/export/disputed" component={SC.AdminExportDisputed} />,
  <Route exact path="/features" component={SC.Update} />,
  <Route exact path="/features/update" component={SC.Update} />,
  <Route exact path="/features/referral" component={SC.Referral} />,
  <Route exact path="/integration/manage" component={SC.Integration} />,
  <Route
    exact
    path="/integration/export/manage"
    component={SC.IntegrationExport}
  />,
  <Route exact path="/integration/tms" component={SC.TMSManagment} />,
  <Route exact path="/members/users" component={SC.Members} />,
  <Route exact path="/members/admins" component={SC.Admins} />,
  <Route exact path="/content/disputed" component={SC.Confirmed} />,
  <Route exact path="/request-detail" component={SC.RequestDetail} />,
  <Route exact path="/requests/inprogress" component={SC.RequestInComplete} />,
  <Route exact path="/requests/complete" component={SC.RequestComplete} />,
  <Route exact path="/orders/inprogress" component={SC.OrderInComplete} />,
  <Route exact path="/orders/complete" component={SC.OrderComplete} />,
  <Route exact path="/search-detail/empty" component={SC.EmptySearchDetail} />,
  <Route
    exact
    path="/search-detail/export"
    component={SC.ExportSearchDetail}
  />,
  <Route exact path="/inventory" component={SC.Inventory} />,
  <Route exact path="/inventory/list" component={SC.Inventory} />,
  <Route exact path="/inventory/export" component={SC.ExportInventory} />,
  <Route
    exact
    path="/inventory/streetturn"
    component={SC.InventoryStreetTurn}
  />,
  <Route exact path="/" component={SC.SearchDetail} />,
  <Route exact path="/AI" component={SC.AIGroup} />,
  <Route exact path="/AI/group" component={SC.AIGroup} />,
  <Route exact path="/AI/Chat" component={SC.AIChat} />,
  <Route exact path="/AI/Chat/:directChatID" component={SC.AIChat} />,
  <Route exact path="/" component={SC.Landing} noLayout />,
  <Route exact path="/how-it-works" component={SC.How} noLayout />,
  <Route exact path="/Register" component={SC.Register} noLayout />,
  <Route exact path="/about" component={SC.About} noLayout />,
  <Route exact path="/Amirbios" component={SC.Amirbios} noLayout />,
  <Route exact path="/terminal" component={SC.GateSchedule} />,
  <Route exact path="/terminal/admin" component={SC.GateScheduleAdmin} />,
  <Route exact path="/terminal/map" component={SC.Ports} />,
  <Route exact path="/terminal/GateSchedule" component={SC.GateSchedule} />,
  <Route
    exact
    path="/terminal/admin/GateSchedule"
    component={SC.GateScheduleAdmin}
  />,
  <Route
    exact
    path="/terminal/ReceivablesSchedule"
    component={SC.ReceivablesSchedule}
  />,
  <Route
    exact
    path="/terminal/admin/ReceivablesSchedule"
    component={SC.ReceivablesScheduleAdmin}
  />,
  <Route exact path="/terminal/appointment" component={SC.appointment} />,
  <Route exact path="/terminal/vessel" component={SC.VesselSchedule} />,
  <Route
    exact
    path="/terminal/admin/vessel"
    component={SC.VesselScheduleAdmin}
  />,
  <Route
    exact
    path="/terminal/admin/scrape/setting"
    component={SC.ScrapeSetting}
  />,
  <Route
    exact
    path="/Shared_Container/:token"
    component={SC.SharedContainer}
  />,
  <Route exact path="/e/:empty" component={SC.Empty} noLayout />,
  <Route exact path="/contact" component={SC.Contact} noLayout />,
  <Route exact path="/forget" component={SC.ForgetPass} noLayout />,
  <Route exact path="/privacy" component={SC.Privacy} noLayout />,
];
