import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  content: {
    minWidth: '0%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    margin: '0 20px',
    overflow: 'auto',
    transition: 'all ease 0.3s',
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '14px 4px 5px',
    [BP.down('sm')]: {
      flexDirection: 'column',
      margin: '10px',
    },
  },
  card: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    // height: 160,
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    marginBottom: 5,
  },

  list: {
    margin: '20px 0 0px',
    width: '100%',
    minWidth: 1300,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [BP.down('sm')]: {
      marginTop: 15,
      marginBottom: 10,
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
    ['@media (max-width:1440px)']: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    ['@media (max-width:1440px)']: {
      marginTop: 10,
    },
  },
  row2: {
    display: 'flex',
    flexDirection: 'row',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: 1,
    color: '#303030',
  },
  titleBlue: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: 1,
    color: '#007fff',
    margin: '0 20px 0 10px',
    cursor: 'pointer',
  },

  import: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
    cursor: 'pointer',
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: 1,
    color: '#44a570',
    '& > svg': {
      height: 28,
      width: 28,
    },
  },

  printIcon: {
    height: 28,
    width: 28,
    fill: '#007fff',
    marginLeft: 10,
    cursor: 'pointer',
  },
  select: {
    width: '100%',
    fontFamily: 'Averta Light',
    '& > div': {
      border: 'solid 1px #cccccc',
    },

    '& > div:first-child > div': {
      padding: '0 0 0 10px',
    },
    '& svg': {
      fill: '#f8b100',
    },
  },
  date: {
    boxSizing: 'border-box',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 4,
    justifyContent: 'center',
    fontFamily: 'Averta Light',
    padding: '0 10px',
    border: 'solid 1px #cccccc',
    '& div': {},
    '& input': {
      width: '100%',
      padding: 0,
      cursor: 'pointer',
      fontFamily: 'Averta Light',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '& button': {
      padding: 8,
    },
  },
  loc: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
    '& svg': {
      fill: '#f8b100',
    },
  },
  ml15: {
    marginLeft: 15,
  },
  input: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 36,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    border: 'solid 1px #cccccc',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
  },
  text: {
    fontFamily: 'Averta Light',
    fontSize: 13,
    fontWeight: 300,
    lineHeight: 1.5,
    color: '#303030',
  },
  but: {
    width: 77,
    height: 27,
    borderRadius: 5,
    backgroundColor: '#f8b100',
    fontFamily: 'Averta Light',
    fontSize: 12,
    fontWeight: 300,
    color: '#fff',
    padding: 0,
  },
  red: {
    backgroundColor: '#e4464b !important',
  },
  blue: {
    backgroundColor: '#007fff !important',
    marginLeft: 10,
  },
  rowRound: {
    width: 180,
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    borderRadius: 20,
    backgroundColor: '#fff',
    fontFamily: 'Averta Light',
    fontSize: 15,
    fontWeight: 300,
    lineHeight: 1.27,
    color: '#9f9f9f',
    marginLeft: 25,
  },
  text2: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.2s ease-in',
  },

  tableRow: {
    minWidth: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 61,
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    margin: '10px 0',
    fontFamily: 'Averta Light',
    fontSize: 12,
    fontWeight: 300,
    color: '#43424b',
    textAlign: 'center',

    [BP.up('lg')]: {
      fontSize: 14,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
    },
  },
  thead: {
    display: 'flex',
    fontFamily: 'Averta Bold',
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.2,
    color: '#43424b',
    textAlign: 'center',
    alignItems: 'center',
    [BP.up('lg')]: {
      fontSize: 15,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
    },
  },
  tbody: {
    // display: 'flex',
    // flexDirection: 'column',
    // width:'fit-content'
  },
  resText: {
    color: '#007fff',
  },
  bigText: {
    lineHeight: 2.7,
    color: '#303030',
  },
  bold: {
    fontFamily: 'Averta Bold',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 3.86,
    color: '#43424b',
  },
  editIcon: {
    height: 20,
    width: 20,
    fill: '#f8b100',
    [BP.up('lg')]: {
      height: 25,
      width: 25,
    },
  },
  replyIcon: {
    height: 20,
    width: 20,
    fill: '#000000',
    [BP.up('lg')]: {
      height: 25,
      width: 25,
    },
  },
  delIcon: {
    height: 20,
    width: 20,
    fill: '#e4464b',
    [BP.up('lg')]: {
      height: 25,
      width: 25,
    },
  },
  loading: {
    height: '100%',
    margin: 0,
  },
  tabActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listContainer: {
    overflow: 'auto',
    width: '100%',
    boxSizing: 'border-box',
    transition: 'all ease 0.3s',
    '&::-webkit-scrollbar-track': {
      transition: 'all 1s ease',
      background: '#bebebe',
    },
    '&::-webkit-scrollbar-thumb': {
      transition: 'all 1s ease',
      borderRadius: '3px',
      background: '#494949',
    },
    '&::-webkit-scrollbar': {
      transition: 'all 1s ease',
      width: '8px',
    },
  },
  checkbox: {
    '& svg': {
      fill: '#f8b100',
    },
    '& hover': {
      background: '#f8b1001a',
    },
  },
  textInput: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 36,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    border: 'solid 1px #cccccc',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
  },
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ShareEmail: {
    color: '#ffffff',
    background: '#007fff',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: '#ffffff',
      background: '#007fff',
    },
  },
  numberInput: {
    '& div': {
      '& input': {
        height: '28px',
      },
    },
  },
  suggestionsContainer: {
    borderBottom: '1px solid rgb(218,220,224)',
    paddingBottom: '10px',
  },
  copyActions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    gap: '6px',
    '& button': {
      padding: 6,
    },
  },
  copySection: {
    flexDirection: 'column',
    borderRadius: '4px',
    background: '#d5d5d5',
    color: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px 10px',
    '& div': {
      flex: '1',
      paddingLeft: '10px',
    },
  },
  shareLinkContainer: {
    flex: '1',
  },
  modalBox: {
    width: '770px',
    minHeight: '397px',
    background: '#FFFFFF',
    borderRadius: '5px',
    padding: '10px 15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  shareLinkBox: {
    position: 'fixed',
    bottom: 15,
    left: 15,
    minHeight: '250px !important',
    zIndex: '9999',
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
  },
  containersTableContainer: {
    maxHeight: '300px',
    overflow: 'auto',
    flex: '1',
  },
  closeModal: {
    textAlign: 'end',
    '& img': {
      width: '10px',
      height: '10px',
      cursor: 'pointer',
    },
  },
  modalTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '23px',
    color: '#000000',
    margin: '0 0 20px 0',
  },
  editContainerInput: {
    background: 'transparent',
    padding: '4px 2px 5px 8px',
    borderRadius: '5px',
    border: '1px solid black',
  },
  updateContainersBtnSpan: {
    display: 'flex',
    justifyContent: 'space-between',
    justifyContent: 'flex-end',
  },
  updateContainersBtnClose: {
    background: '#007fff',
    borderRadius: '5px',
    border: 'none',
    color: '#ffffff',
    fontSize: '13px',
    padding: '10px 15px',
    margin: '15px',
    display: 'block',
  },
  updateContainersBtnSave: {
    background: '#3c845d',
    borderRadius: '5px',
    border: 'none',
    color: '#ffffff',
    fontSize: '13px',
    padding: '10px 15px',
    margin: '15px',
    display: 'block',
  },
  containerTable: {
    width: '100%',
    '& tr': {
      display: 'flex',
    },
    '& th': {
      color: '#777575',
      fontSize: '13px',
      padding: '10px',
    },
    '& td': {
      padding: '0 10px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#000000',
      fontSize: '13px',
      height: '27px',
      borderBottom: '1px solid #dedede',
      '& label': {
        margin: 0,
      },
      '& svg': {
        fill: '#000000',
      },
    },
  },
  containerChecBox: {
    accentColor: '#000000',
  },
  chassisNameContainer: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: 25,
      height: 25,
      margin: '0 10px',
    },
  },

  inviteIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '8px 0',
    borderBottom: '1px solid rgb(218,220,224)',
  },
  inviteIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    fontWeight: 'bold',
    borderBottom: '2px solid #007fff',
    padding: '10px 6px',
    color: '#007fff',
  },
  email: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  removeEmail: {
    width: 28,
    height: 28,
  },
  emailInputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 5,
    borderBottom: '1px solid rgb(218,220,224)',
    paddingBottom: '8px',
    '& input': {
      flex: '1',
    },
  },
  emailBadge: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgb(218,220,224)',
    padding: '1.5px',
    borderRadius: '16px',
    gap: 6,
    width: 'fit-content',
  },
  emailAvatar: {
    width: 28,
    height: 28,
  },
  removeEmailIcon: {
    width: '1rem',
  },
  suggestion: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '3px 0',
  },
  suggestionInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  suggestionAvatar: {
    width: 30,
    height: 30,
  },
  emailCheckBox: {
    '& svg': {
      fill: '#007fff',
    },
    '& hover': {
      background: '#007fff45',
    },
  },
  containerActions: {
    display: 'flex',
    alignItems: 'cneter',
    justifyContent: 'space-between',
  },
  containerRowRound: {
    overflow: 'hidden',
    width: '100%',
    minWidth: 160,
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    borderRadius: 5,
    backgroundColor: '#d7d7d7',
    fontFamily: 'Averta Light',
    fontSize: 15,
    fontWeight: 300,
    lineHeight: 1.27,
    color: '#9f9f9f',
    cursor: 'pointer',
    ['@media (max-width:450px)']: {
      marginLeft: 0,
      marginTop: 5,
      width: '100%',
    },
  },
  chassisText: {
    textAlign: 'center',
    display: 'flex',
    color: '#000',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    padding: 5,
    flex: '1',
    '&:hover': {
      background: '#edecec',
    },
    '&:first-child': {
      borderRight: '2px solid #ffffff',
      borderRadius: '5px 0 0 5px',
    },
    '&:last-child': {
      borderRadius: '0 5px 5px 0',
    },
  },
  activeChassis: {
    backgroundColor: '#f8b100 !important',
    color: '#000 !important',
    '&:hover': {
      background: '#ffd616 !important',
    },
  },
  additionalField: {
    display: 'flex',
  },
  containerInput: {
    display: 'flex',
    alignItems: 'center',
    // overflow: 'hidden',
  },
  containerBadge: {
    background: '#e5e5e5',
    height: '100%',
    display: 'flex',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    padding: '0 14px',
    whiteSpace: 'nowrap',
    width: '50px',
    minWidth: '50px',
  },
  containerBadgeTA: {
    background: '#e5e5e5',
    height: '100%',
    display: 'flex',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    padding: '0 55px',
    whiteSpace: 'nowrap',
    width: 'max-Content',
    minWidth: '54px',
  },
}));
