import React, { useEffect, useState, useRef } from 'react';
import styles from './styles';
import { Icons, TerminalsIcon } from '@/assets';
import {
  Tooltip,
  Grid,
  Button,
  IconButton,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
} from '@material-ui/core';
import {
  DeleteRounded,
  CreateRounded,
  Share as ShareIcon,
} from '@material-ui/icons';
import { ReactSVG } from 'react-svg';

// import DateInput from '@/components/DateInput';
import clsx from 'clsx';
// import SelectBox from 'react-select';
import {
  getPerDiem,
  // adminChangeScrapeSetting,
  // adminChangeScrapeSettingPr,
  // getGateSchedule,
  // getScrapeGateList,
  // getScrapeRecivablesList,
  // getScrapeVesselsList,
  // getScrapeVesselsListPr,
} from '@/actions';
import { useNotify, Loading } from 'react-admin';
import { getEmptyReceivingSchedule, getSslList } from '@/actions';
import EditFields from './editFields';

export default (
  {
    //   TerminalsList,
    //   PortsList,
    //   DATATableRecivable,
    //   DATATableVessels,
    //   dataTableVesselsTerminal,
    //   dataTableVesselsSsl,
    //   // getGateScheduleList,
    //   // getRecivablesScheduleList,
    //   // getVesselsScheduleList,
    //   // getVesselsScheduleListTerminal,
    //   // getVesselsScheduleListSsl,
  }
) => {
  const cls = styles();
  const [loading, setLoading] = React.useState(false);
  const [sslsList, setSslsList] = useState([]);
  const [DATADefault, setDATADefault] = React.useState([]);
  const [PerDiems, setPerDiems] = useState([]);

  const [reconstructedList, setreconstructedList] = useState([]);
  const [sslsSelectOptions, setSslsSelectOptions] = useState([]);

  //   const [selectedDate, setSelectedDate] = useState(new Date());
  //   const [statusChange, setStatusChange] = useState({});
  //   const [selectedType, setSelectedType] = useState(1);
  //   const [dataTypeChange, setdataTypeChange] = useState('terminal');
  //   const [Terminals, setTerminals] = React.useState(TerminalsList);
  //   const [TerminalsReciv, setTerminalsReciv] =
  //     React.useState(DATATableRecivable);
  //   const [TerminalsVessels, setTerminalsVessels] =
  //     React.useState(DATATableVessels);
  //   const [TerminalsVesselsTerminal, setTerminalsVesselsTerminal] =
  //     React.useState(dataTableVesselsTerminal);
  //   const [TerminalsVesselsSsl, setTerminalsVesselsSsl] =
  //     React.useState(dataTableVesselsSsl);
  //   console.log('selectedType', selectedType);
  const amirbiosRef = useRef(null);
  const tableRef = useRef(null);
  const amirbios1Ref = useRef(null);
  const amirbios2Ref = useRef(null);
  const notify = useNotify();
  const [popupMessage, setPopupMessage] = useState({
    DATA: null,
    loading: true,
    popUp: false,
  });

  const getEmptyReceivingScheduleList = () => {
    setLoading(true);
    getEmptyReceivingSchedule(`2024-09-29`)
      .then((res) => {
        // setDataTable(res.data.restrictions);
        setDATADefault(res.data.default);
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  const getSslListFanc = () => {
    setLoading(true);
    getSslList()
      .then((res) => {
        // console.log('res', res);

        const sslsRes = Object.values(res.data.ssl_list);
        if (sslsRes) {
          setSslsList(sslsRes); // Set the state directly
        }
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  useEffect(() => {
    if (sslsList.length > 0) {
      const options = sslsList.map((ssl, index) => ({
        label: ssl.name_full,
        slug: ssl.name,
        id: ssl.id,
        value: index + 1,
      }));

      // Set portSelectOptions state
      setSslsSelectOptions(options);
      // console.log(
      //   'item.slug === DATA',
      //   options.find((item) => item.slug === DATA)
      // );

      // setSelectedSsl(options.find((item) => item.slug === DATA));
    }
  }, [sslsList]); // Dependencies array with portsList

  useEffect(() => {
    getEmptyReceivingScheduleList();
    getSslListFanc();
  }, []);

  //   const [tableWidth, setTableWidth] = useState(0);
  //   const [spanWidth, setSpanWidth] = useState(0);

  //   const scheduleType = [
  //     {
  //       label: 'Gate',
  //       slug: 'gate',
  //       value: 1,
  //     },
  //     {
  //       label: 'Recivables',
  //       slug: 'recivables',
  //       value: 2,
  //     },
  //     {
  //       label: 'Vessel',
  //       slug: 'vessel',
  //       value: 3,
  //     },
  //   ];
  //   const typeSelectOptions = [
  //     {
  //       label: 'Terminal',
  //       slug: 'terminal',
  //       value: 'terminal',
  //     },
  //     {
  //       label: 'SSL',
  //       slug: 'ssl',
  //       value: 'ssl',
  //     },
  //     {
  //       label: 'Priority',
  //       slug: 'all',
  //       value: 'all',
  //     },
  //   ];

  //   const [form, setFormData] = React.useState({
  //     scheduleType: scheduleType[0],
  //     type: typeSelectOptions[0],
  //     size: null,
  //     ssl: null,
  //     date: new Date(),
  //     count: '',
  //     loc: null,
  //     containers: [],
  //   });
  //   const [formReciv, setFormRecivData] = React.useState({
  //     scheduleType: scheduleType[0],
  //     size: null,
  //     ssl: null,
  //     date: new Date(),
  //     count: '',
  //     loc: null,
  //     containers: [],
  //   });
  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  const getPerDiemList = () => {
    setLoading(true);
    getPerDiem()
      .then((res) => {
        if (res) {
          setPerDiems(res.data.per_diems);
        }
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  useEffect(() => {
    getPerDiemList();
  }, []);

  // if (PerDiems) {

  useEffect(() => {
    const reconstructedJson = {};

    for (const perDiem of PerDiems) {
      const shippingLineAlias = perDiem.shipping_line.alias;
      const specificationKey = `${perDiem.specification.size} ${perDiem.specification.name} ${perDiem.specification.model}`;

      if (!reconstructedJson[shippingLineAlias]) {
        reconstructedJson[shippingLineAlias] = {};
      }

      reconstructedJson[shippingLineAlias][specificationKey] = {
        id: perDiem.id,
        phase: perDiem.phase,
        type: perDiem.type,
        with_chassis: perDiem.with_chassis,
        start_day: perDiem.start_day,
        end_day: perDiem.end_day,
        per_diem_amount: perDiem.per_diem_amount,
        saturday: perDiem.saturday,
        sunday: perDiem.sunday,
        saturday_lfd: perDiem.saturday_lfd,
        sunday_lfd: perDiem.sunday_lfd,
        pickup_day_included: perDiem.pickup_day_included,
        return_day_included: perDiem.return_day_included,
        holidays_included: perDiem.holidays_included,
        status: perDiem.status,
        created_at: perDiem.created_at,
        updated_at: perDiem.updated_at,
        shipping_line: perDiem.shipping_line,
        specification: perDiem.specification,
      };
    }
    setreconstructedList(reconstructedJson);
    // }
  }, [PerDiems]);
  console.log('diammm', reconstructedList);
  const formattedObject = reconstructedList['ANL']
    ? Object.entries(reconstructedList['ANL']).reduce((acc, [key, value]) => {
        acc[key] = { id: value.id }; // افزودن id به آبجکت
        if (value.phase) {
          // بررسی اینکه آیا property phase وجود دارد
          acc[key].phase = value.phase;
        }
        return acc;
      }, {})
    : {};

  const foundId = formattedObject['20  Dry']?.id || null; // or any default value you prefer
  console.log('diammm', foundId);

  //   const getRecivablesScheduleList = () => {
  //     setLoading(true);
  //     getScrapeRecivablesList('LA/LB')
  //       .then((res) => {
  //         if (res) {
  //           setTerminalsReciv(res.data.scrapeSettings);
  //         }
  //         let keys = Object.keys(TerminalsReciv);

  //         if (keys.length > 0) {
  //           setLoading(false);
  //         }
  //       })
  //       .catch((err) => {
  //         notify(err, 'warning');
  //         setLoading(false);
  //       });
  //   };
  //   const getVesselsScheduleListPr = () => {
  //     setLoading(true);
  //     getScrapeVesselsListPr()
  //       .then((res) => {
  //         if (res) {
  //           console.log('ress0', res.data.scrapeSettings);
  //           setTerminalsVessels(res.data.scrapeSettings);
  //         }
  //         let keys = Object.keys(TerminalsVessels);
  //         console.log('TerminalsVessels', Object.keys(TerminalsVessels));

  //         if (keys.length > 0) {
  //           console.log('TerminalsVessels', TerminalsVessels);
  //           setLoading(false);
  //         }
  //       })
  //       .catch((err) => {
  //         notify(err, 'warning');
  //         setLoading(false);
  //       });
  //   };
  //   const getVesselsScheduleListTerminal = () => {
  //     const source = `&source=Terminal`;
  //     setLoading(true);
  //     getScrapeVesselsList('LA/LB', source)
  //       .then((res) => {
  //         if (res) {
  //           setTerminalsVesselsTerminal(res.data.scrapeSettings);
  //           console.log('TerminalsVesselsTerminal', TerminalsVesselsTerminal);
  //         }
  //         let keys = Object.keys(TerminalsVesselsTerminal);

  //         if (keys.length > 0) {
  //           setLoading(false);
  //         }
  //       })
  //       .catch((err) => {
  //         notify(err, 'warning');
  //         setLoading(false);
  //       });
  //   };
  //   const getVesselsScheduleListSsl = () => {
  //     const source = `&source=SSL`;
  //     setLoading(true);
  //     getScrapeVesselsList('LA/LB', source)
  //       .then((res) => {
  //         if (res) {
  //           setTerminalsVesselsSsl(res.data.scrapeSettings);
  //         }
  //         let keys = Object.keys(TerminalsVesselsSsl);

  //         if (keys.length > 0) {
  //           setLoading(false);
  //         }
  //       })
  //       .catch((err) => {
  //         notify(err, 'warning');
  //         setLoading(false);
  //       });
  //   };

  //   const todayy = new Date();
  //   const formattedSelectedDate = formatDate(selectedDate);
  //   let keysGate = Object.keys(TerminalsList);
  //   let keysReciv = Object.keys(DATATableRecivable);
  //   let keysVessels = Object.keys(DATATableVessels);
  //   // getGateScheduleList,
  //   //   getRecivablesScheduleList,
  //   //   getVesselsScheduleList,
  //   const handleTableData = (item) => {
  //     console.log('item', item);

  //     setLoading(true);

  //     switch (item) {
  //       case 1:
  //         getGateScheduleList();
  //         break;

  //       case 2:
  //         getRecivablesScheduleList();
  //         console.log('TerminalsReciv', TerminalsReciv);

  //         setTimeout(() => {
  //           if (TerminalsReciv) {
  //             // setTerminalsReciv(TerminalsReciv);
  //             setLoading(false);
  //           }
  //         }, 500);
  //         break;

  //       case 3:
  //         getVesselsScheduleListTerminal();
  //         setTimeout(() => {
  //           console.log('TerminalsVesselsTerminal', TerminalsVesselsTerminal);

  //           // setTerminalsVesselsTerminal(TerminalsVesselsTerminal);
  //           setLoading(false);
  //         }, 500);

  //         break;

  //       case 'all':
  //         console.log('all');
  //         getVesselsScheduleListPr();
  //         setTimeout(() => {
  //           console.log('dataTypeChange', dataTypeChange);
  //           console.log('TerminalsVessels', TerminalsVessels);

  //           // setTerminalsVessels(TerminalsVessels);
  //           setLoading(false);
  //         }, 500);

  //         break;

  //       case 'terminal':
  //         getVesselsScheduleListTerminal();
  //         setTimeout(() => {
  //           console.log('TerminalsVesselsTerminal', TerminalsVesselsTerminal);

  //           // setTerminalsVesselsTerminal(TerminalsVesselsTerminal);
  //           setLoading(false);
  //         }, 500);

  //         break;

  //       case 'ssl':
  //         getVesselsScheduleListSsl();
  //         setTimeout(() => {
  //           console.log('TerminalsVesselsSsl', TerminalsVesselsSsl);

  //           // setTerminalsVesselsSsl(TerminalsVesselsSsl);
  //           setLoading(false);
  //         }, 500);

  //         break;

  //       // default:
  //       //   console.error('Unknown dataTypeChange:', dataTypeChange);
  //       //   break;

  //       // setLoading(false);

  //       default:
  //         console.error('Unknown item:', item);
  //         setLoading(false);
  //         break;
  //     }
  //   };

  //   useEffect(() => {
  //     getGateScheduleList();

  //     // setTerminals(TerminalsList);
  //     // setTerminalsReciv(DATATableRecivable);
  //     // setTerminalsVessels(DATATableVessels);
  //   }, []);
  //   // console.log(Terminals);

  //   const portSelectOptions = Object.keys(PortsList).map((i, index) => ({
  //     label: PortsList[index].name,
  //     slug: PortsList[index].slug,
  //     value: index + 1,
  //   }));

  //   const handleChangeScrapeSetting = (terminalId, key, value, idx) => {
  //     console.log('terminalId, key, value, idx', terminalId, key, value, idx);

  //     adminChangeScrapeSetting(terminalId, key, value)
  //       .then((res) => {
  //         console.log('apiresss', res.data.scrapeSetting);
  //         if (res) {
  //           setTerminals((prevTerminals) =>
  //             prevTerminals?.map((item, index) =>
  //               index === idx ? { ...item, ...res.data.scrapeSetting } : item
  //             )
  //           );
  //         }
  //         console.log('Terminals', Terminals);

  //         notify('Successfully Updated!', 'success');
  //       })
  //       .catch((err) => {
  //         notify(err, 'warning');
  //       });
  //   };

  //   const handleChangeScrapeSettingReciv = (terminalId, key, value, idx) => {
  //     adminChangeScrapeSetting(terminalId, key, value)
  //       .then((res) => {
  //         // console.log('apiresss', res.data.scrapeSetting);
  //         if (res) {
  //           setTerminalsReciv((prevTerminals) =>
  //             prevTerminals?.map((item, index) =>
  //               index === idx ? { ...item, ...res.data.scrapeSetting } : item
  //             )
  //           );
  //         }
  //         // console.log('TerminalsReciv', TerminalsReciv);

  //         notify('Successfully Updated!', 'success');
  //       })
  //       .catch((err) => {
  //         notify(err, 'warning');
  //       });
  //   };

  //   const handleChangeScrapeSettingVesselsPr = (ID, value, idx) => {
  //     console.log('terminalId, key, value, idx', ID, value, idx);

  //     adminChangeScrapeSettingPr(ID, value, idx)
  //       .then((res) => {
  //         if (res) {
  //           setTerminalsVessels((prevTerminals) =>
  //             prevTerminals?.map((item, index) =>
  //               index === idx ? { ...item, ...res.data.scrapeSetting } : item
  //             )
  //           );
  //         }
  //         console.log('TerminalsVessels', TerminalsVessels);

  //         notify('Successfully Updated!', 'success');
  //       })
  //       .catch((err) => {
  //         notify(err, 'warning');
  //       });
  //   };

  //   const handleChangeScrapeSettingVesselsTerminal = (
  //     terminalId,
  //     key,
  //     value,
  //     idx
  //   ) => {
  //     console.log('terminalId, key, value, idx', terminalId, key, value, idx);

  //     adminChangeScrapeSetting(terminalId, key, value)
  //       .then((res) => {
  //         // console.log('apiresss', res.data.scrapeSetting);
  //         if (res) {
  //           setTerminalsVesselsTerminal((prevTerminals) =>
  //             prevTerminals?.map((item, index) =>
  //               index === idx ? { ...item, ...res.data.scrapeSetting } : item
  //             )
  //           );
  //         }

  //         notify('Successfully Updated!', 'success');
  //       })
  //       .catch((err) => {
  //         notify(err, 'warning');
  //       });
  //   };

  //   const handleChangeScrapeSettingVesselsSsl = (terminalId, key, value, idx) => {
  //     console.log('terminalId, key, value, idx', terminalId, key, value, idx);

  //     adminChangeScrapeSetting(terminalId, key, value)
  //       .then((res) => {
  //         // console.log('apiresss', res.data.scrapeSetting);
  //         if (res) {
  //           setTerminalsVesselsSsl((prevTerminals) =>
  //             prevTerminals?.map((item, index) =>
  //               index === idx ? { ...item, ...res.data.scrapeSetting } : item
  //             )
  //           );
  //         }

  //         notify('Successfully Updated!', 'success');
  //       })
  //       .catch((err) => {
  //         notify(err, 'warning');
  //       });
  //   };

  //   const setForm = (it, val) => setFormData({ ...form, [it]: val });
  //   const setFormReciv = (it, val) =>
  //     setFormRecivData({ ...formReciv, [it]: val });

  //   function getCurrentWeekDates(daydate) {
  //     const today = new Date();

  //     // Adjust for Monday-based weeks: getDay() returns 0 for Sunday, 1 for Monday, etc.
  //     const currentDay = (daydate.getDay() + 6) % 7; // Adjust so Monday is 0 and Sunday is 6

  //     const firstDayOfWeek = new Date(daydate);
  //     firstDayOfWeek.setDate(daydate.getDate() - currentDay);

  //     const dates = [];
  //     for (let i = 0; i < 7; i++) {
  //       const date = new Date(firstDayOfWeek);
  //       date.setDate(firstDayOfWeek.getDate() + i);
  //       dates.push(date);
  //     }
  //     return dates;
  //   }
  //   // Format the date to separate day, abbreviated month, and year
  //   function formatDate(date, format) {
  //     const day = date.getDate().toString().padStart(2, '0');
  //     const dayNumber = date.getDate();
  //     const monthIndex = date.getMonth();
  //     const year = date.getFullYear();
  //     const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  //     const monthNames = [
  //       'Jan',
  //       'Feb',
  //       'Mar',
  //       'Apr',
  //       'May',
  //       'Jun',
  //       'Jul',
  //       'Aug',
  //       'Sep',
  //       'Oct',
  //       'Nov',
  //       'Dec',
  //     ];
  //     const dayName = dayNames[date.getDay()];
  //     const monthNumber = String(monthIndex + 1).padStart(2, '0'); // Add leading zero if necessary
  //     const month = monthNames[monthIndex];
  //     if (format == 1) {
  //       return `${year}-${monthNumber}-${day}`;
  //     } else {
  //       return { day, dayNumber, dayName, monthNumber, month, year };
  //     }
  //   }
  //   const Reset = () => {
  //     setSelectedDate(todayy);
  //     setFormData({
  //       ports: null,
  //       date: new Date(),
  //     });
  //   };

  //   //https://testapi.smart-turn.com/api/terminals/gate-schedule?start_date=2024-04-20&end_date=2024-04-30&terminal=7
  //   const weekDates = getCurrentWeekDates(selectedDate);
  //   const formattedDates = weekDates.map((date) => formatDate(date));
  //   const formattedCurrentWeekDates = weekDates.map((date) =>
  //     formatDate(date, 1)
  //   );

  //   var today = new Date();
  //   var dd = String(today.getDate()).padStart(2, '0');
  //   var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  //   var yyyy = today.getFullYear();

  //   today = { month: mm, day: dd, year: yyyy };

  //   const todayIndex = formattedDates.findIndex(
  //     (date) =>
  //       date.day === parseInt(today.day) &&
  //       date.monthNumber === parseInt(today.month) &&
  //       date.year === today.year
  //   );

  //   // const originalDate = "2024-04-20T04:31:52.000000Z";
  //   const formatUpdatedAtDate = (dateString) => {
  //     const date = new Date(dateString);
  //     const formattedDate =
  //       new Date(date).toLocaleDateString('en-US', {
  //         month: '2-digit',
  //         day: '2-digit',
  //         year: '2-digit',
  //       }) +
  //       ' ' +
  //       new Date(date).toLocaleTimeString('en-US', {
  //         hour: '2-digit',
  //         minute: '2-digit',
  //         hour12: false,
  //       });
  //     return formattedDate;
  //   };

  //   // React.useEffect(() => {
  //   function hasdata(date, flag) {
  //     if (flag == 'no') {
  //       if (formattedDates) {
  //         document.getElementById('datediv').style.color = '#ff0000';
  //       }
  //     }
  //   }
  //   // });

  //   useEffect(() => {
  //     const handleScroll = () => {
  //       if (amirbiosRef.current && amirbios1Ref.current) {
  //         const amirbiosTop = amirbiosRef.current.getBoundingClientRect().top;

  //         if (amirbiosTop <= -10) {
  //           amirbios2Ref.current.classList.add(cls.fix);
  //           tableRef.current.classList.add(cls.tableTopMargin);
  //         } else {
  //           amirbios2Ref.current.classList.remove(cls.fix);
  //           tableRef.current.classList.remove(cls.tableTopMargin);
  //         }
  //       }
  //       syncScroll('amirbios');
  //     };
  //     const updateWidth = () => {
  //       if (tableRef.current && amirbios1Ref.current) {
  //         const tableCurrentWidth =
  //           tableRef.current.getBoundingClientRect().width;
  //         setTableWidth(tableCurrentWidth);
  //       }
  //     };

  //     const updateHeadWidth = () => {
  //       if (amirbiosRef.current && amirbios2Ref.current) {
  //         const tableeCurrentWidth =
  //           amirbiosRef.current.getBoundingClientRect().width;
  //         setSpanWidth(tableeCurrentWidth);
  //       }
  //     };

  //     const syncScroll = (source) => {
  //       if (amirbiosRef.current && amirbios2Ref.current) {
  //         if (source === 'amirbios') {
  //           amirbios2Ref.current.scrollLeft = amirbiosRef.current.scrollLeft;
  //         } else if (source === 'amirbios2') {
  //           amirbiosRef.current.scrollLeft = amirbios2Ref.current.scrollLeft;
  //         }
  //       }
  //     };

  //     // Initial width setting
  //     updateWidth();
  //     updateHeadWidth();

  //     window.addEventListener('scroll', handleScroll);
  //     window.addEventListener('scroll', updateWidth);
  //     window.addEventListener('resize', updateWidth);
  //     window.addEventListener('scroll', updateHeadWidth);
  //     window.addEventListener('resize', updateHeadWidth);
  //     if (amirbiosRef.current) {
  //       amirbiosRef.current.addEventListener('scroll', () =>
  //         syncScroll('amirbios')
  //       );
  //     }
  //     if (amirbios2Ref.current) {
  //       amirbios2Ref.current.addEventListener('scroll', () =>
  //         syncScroll('amirbios2')
  //       );
  //     }

  //     // Cleanup on component unmount
  //     return () => {
  //       window.removeEventListener('scroll', handleScroll);
  //       window.removeEventListener('scroll', updateWidth);
  //       window.removeEventListener('resize', updateWidth);
  //       window.removeEventListener('scroll', updateHeadWidth);
  //       window.removeEventListener('resize', updateHeadWidth);
  //       if (amirbiosRef.current) {
  //         amirbiosRef.current.removeEventListener('scroll', () =>
  //           syncScroll('amirbios')
  //         );
  //       }
  //       if (amirbios2Ref.current) {
  //         amirbios2Ref.current.removeEventListener('scroll', () =>
  //           syncScroll('amirbios2')
  //         );
  //       }
  //     };
  //   }, [
  //     tableWidth,
  //     spanWidth,
  //     Terminals,
  //     selectedType,
  //     scheduleType,
  //     form,
  //     formatDate,
  //   ]);

  //   useEffect(() => {
  //     if (amirbios2Ref.current) {
  //       amirbios2Ref.current.style.width = `${spanWidth - 4}px`;
  //     }
  //   }, [spanWidth]);

  //   useEffect(() => {
  //     if (amirbios1Ref.current) {
  //       amirbios1Ref.current.style.width = `${tableWidth - 4}px`;
  //     }
  //   }, [tableWidth]);

  //   if (amirbios2Ref.current) {
  //     amirbios2Ref.current.style.width = `${spanWidth - 4}px`;
  //   }

  //   if (amirbios1Ref.current) {
  //     amirbios1Ref.current.style.width = `${tableWidth - 4}px`;
  //   }

  return (
    <>
      {popupMessage.popUp && (
        <EditFields
          DATA={popupMessage.DATA}
          Label={popupMessage.Label}
          sslsList={sslsList}
          vessel_id={popupMessage.vessel_id}
          column_name={popupMessage.column_name}
          column_value={popupMessage.DATA}
          Close={() =>
            setPopupMessage((prev) => {
              return {
                ...prev,
                popUp: false,
              };
            })
          }
        />
      )}

      <div ref={amirbiosRef} id="amirbios" className={cls.listContainer}>
        {loading ? (
          <Loading />
        ) : (
          //  form.scheduleType.value === 1 ? (
          <table ref={tableRef} id="table" className={cls.listPerDiam}>
            <thead ref={amirbios2Ref} id="amirbios1">
              <span ref={amirbios1Ref} id="amirbios2" className={cls.thead}>
                <th className={cls.shifttd} style={{ flex: 0.297 }}></th>
                <th className={cls.shifttd} style={{ flex: 0.7 }}>
                  IB DET
                </th>
                <th className={cls.shifttd} style={{ flex: 0.7 }}>
                  OB DET
                </th>
              </span>
            </thead>
            <thead ref={amirbios2Ref} id="amirbios1">
              <span ref={amirbios1Ref} id="amirbios2" className={cls.thead}>
                <th className={cls.shifttd} style={{ flex: 1 }}>
                  SSL
                </th>
                <th className={cls.shifttd} style={{ flex: 1 }}>
                  SIZE/TYPE
                </th>
                <th className={cls.shifttd} style={{ flex: 1 }}>
                  Chassis
                </th>
                <th className={cls.shifttd} style={{ flex: 1 }}>
                  IB Free Days
                </th>
                <th className={cls.shifttd} style={{ flex: 1 }}>
                  IB Tier 1
                </th>
                <th className={cls.shifttd} style={{ flex: 1 }}>
                  IB Tier 1 Charges
                </th>
                <th className={cls.shifttd} style={{ flex: 1 }}>
                  IB Tier 2
                </th>
                <th className={cls.shifttd} style={{ flex: 1 }}>
                  IB Tier 2 Charges
                </th>
                <th className={cls.shifttd} style={{ flex: 1 }}>
                  IB Tier 3
                </th>
                <th className={cls.shifttd} style={{ flex: 1 }}>
                  IB Tier 3 Charges
                </th>
                <th className={cls.shifttd} style={{ flex: 1 }}>
                  OB Free Days
                </th>
                <th className={cls.shifttd} style={{ flex: 1 }}>
                  OB Tier 1
                </th>
                <th className={cls.shifttd} style={{ flex: 1 }}>
                  OB Tier 1 Charges
                </th>
                <th className={cls.shifttd} style={{ flex: 1 }}>
                  OB Tier 2
                </th>
                <th className={cls.shifttd} style={{ flex: 1 }}>
                  OB Tier 2 Charges
                </th>
                <th className={cls.shifttd} style={{ flex: 1 }}>
                  OB Tier 3
                </th>
                <th className={cls.shifttd} style={{ flex: 1 }}>
                  OB Tier 3 Charges
                </th>
              </span>
            </thead>
            <tbody className={cls.tbody}>
              {Object.keys(DATADefault)?.map((key) => (
                <tr className={cls.tableRowPierDiam}>
                  <>
                    <td style={{ flex: 0.062 }} className={cls.shifttd}>
                      {key}
                      {/* <tr className={cls.sslNamesTh}>{kkk}</tr> */}
                    </td>
                    {/* {Object.keys(DATADefault)?.map((key) => (
                      <td
                        rowSpan={13}
                        style={{ flex: 0.9 }}
                        className={cls.shifttd}>
                        {key}
                      </td>
                    ))} */}
                    <span style={{ flex: 1 }} className={cls.sizeSpan}>
                      {reconstructedList[key]
                        ? Object.keys(reconstructedList[key]).map((kkk) => {
                            const formattedObject = reconstructedList[key]
                              ? Object.entries(reconstructedList[key]).reduce(
                                  (acc, [keyy, value]) => {
                                    acc[keyy] = { ...value }; // افزودن id به آبجکت
                                    return acc;
                                  },
                                  {}
                                )
                              : {};

                            return (
                              <>
                                <td className={cls.tableRow}>
                                  <td
                                    style={{ flex: 0.062 }}
                                    className={cls.shiftztd}>
                                    {kkk}
                                  </td>
                                  <td style={{ flex: 1 }} className={cls.sslTr}>
                                    <td
                                      style={{ flex: 1 }}
                                      className={cls.shifttd}>
                                      {formattedObject[kkk]?.with_chassis === 1
                                        ? 'Yes'
                                        : 'No'}
                                    </td>
                                    <td
                                      style={{ flex: 1 }}
                                      className={cls.shifttd}>
                                      1 -{' '}
                                      {formattedObject[kkk]?.start_day || null}
                                    </td>
                                    <td
                                      style={{ flex: 1 }}
                                      className={cls.shifttd}>
                                      -
                                    </td>
                                    <td
                                      style={{ flex: 1 }}
                                      className={cls.shifttd}>
                                      {formatPrice(
                                        formattedObject[kkk]?.per_diem_amount
                                      ) || null}
                                    </td>
                                    <td
                                      style={{ flex: 1 }}
                                      className={cls.shifttd}>
                                      -
                                    </td>
                                    <td
                                      style={{ flex: 1 }}
                                      className={cls.shifttd}>
                                      -
                                    </td>
                                    <td
                                      style={{ flex: 1 }}
                                      className={cls.shifttd}>
                                      -
                                    </td>
                                    <td
                                      style={{ flex: 1 }}
                                      className={cls.shifttd}>
                                      -
                                    </td>
                                    <td
                                      style={{ flex: 1 }}
                                      className={cls.shifttd}>
                                      -
                                    </td>
                                    <td
                                      style={{ flex: 1 }}
                                      className={cls.shifttd}>
                                      -
                                    </td>
                                    <td
                                      style={{ flex: 1 }}
                                      className={cls.shifttd}>
                                      -
                                    </td>
                                    <td
                                      style={{ flex: 1 }}
                                      className={cls.shifttd}>
                                      -
                                    </td>
                                    <td
                                      style={{ flex: 1 }}
                                      className={cls.shifttd}>
                                      -
                                    </td>
                                    <td
                                      style={{ flex: 1 }}
                                      className={cls.shifttd}>
                                      -
                                    </td>
                                    <td
                                      style={{ flex: 1 }}
                                      className={cls.shifttd}>
                                      -
                                    </td>
                                  </td>
                                </td>
                              </>
                            );
                          })
                        : ''}
                    </span>
                  </>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};
